interface Params {
  className: string;
  strokeWidth?: number;
}

export default function CheckMark(params: Params) {
  return (
    <>
      <svg
        className={params.className}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 13l4 4L19 7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={params.strokeWidth || 3}
        />
      </svg>
    </>
  );
}
