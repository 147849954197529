import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';

export default function Checkbox({
  id,
  name,
  checked,
  handleCheckboxChange,
  disabled,
  isCountry,
}: {
  id: string;
  name: string;
  checked: boolean;
  handleCheckboxChange: (name: string) => void;
  disabled?: boolean;
  isCountry?: boolean;
}) {
  const { t } = useTranslation('common');

  const [check, setCheck] = useState(checked);

  function toggleCheckbox() {
    setCheck(!check);
    handleCheckboxChange(name);
  }

  useEffect(() => {
    if (checked !== undefined) {
      setCheck(checked);
    }
  }, [checked]);
  return (
    <div key={name} className="flex items-center">
      <input
        checked={check}
        className="flex-shrink-0 hover:cursor-pointer"
        id={id}
        name={name}
        onChange={() => toggleCheckbox()}
        type="checkbox"
        disabled={disabled}
        data-test-id={`${id}-checkbox`}
      />
      <label className="flex select-none items-center pl-2" htmlFor={id}>
        {isCountry ? (
          <>
            <ReactCountryFlag alt={name} countryCode={name} svg />
            <span className=" ml-1 md:hidden">{name.toUpperCase()}</span>
            <span className="ml-1 hidden md:inline">{t(name)}</span>
          </>
        ) : (
          t(name)
        )}
      </label>
    </div>
  );
}
