import Link from '@/modules/I18n/components/Link';
import { type MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';

import Checkbox from '@/components/Checkbox';
import useStore from '@/store/user';

import CheckMark from '@/components/SVG/CheckMark';
import type { PricingResults } from '@/schemas/DownloadsPage';

export default function WebshopHomePricing({
  className,
  hideButton,
  hideCountries,
  prices,
  pricingData,
  onClick,
}: {
  className?: string;
  hideButton?: boolean;
  hideCountries?: boolean;
  pricingData: PricingResults;
  prices?: Map<string, number>;
  onClick?: MouseEventHandler<HTMLElement> | undefined;
}) {
  const { t } = useTranslation('pricing');

  const userCountry = useStore((state) => state.country);
  const selectedCountries = useStore((state) => state.selectedCountries);
  const addCountry = useStore((state) => state.addCountry);
  const removeCountry = useStore((state) => state.removeCountry);
  const [price, setPrice] = useState(0);

  const currentPackage = pricingData.packages.find((x) => x.name === 'webshop');

  function toggleCountry(name: string) {
    selectedCountries.has(name) ? removeCountry(name) : addCountry(name);
    setNewPrices();
  }

  function setNewPrices() {
    let newPrice = price;
    const tempPrice =
      selectedCountries.size === 0
        ? 0
        : currentPackage.pricePerCountry +
          (currentPackage.pricePerCountry - currentPackage.multiCountryDiscount) *
            (selectedCountries.size - 1);

    newPrice = tempPrice;
    setPrice(newPrice);
  }

  useEffect(() => {
    setNewPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountries]);

  useEffect(() => {
    let selected = false;
    if (selectedCountries.size === 0) {
      Object.keys(pricingData.packages.at(0)?.countries || {}).map((c) => {
        if (c.toUpperCase() === userCountry) {
          toggleCountry(userCountry);
          selected = true;
        }
      });
    }

    if (userCountry && selectedCountries.size === 0 && !selected) toggleCountry('NL');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCountry]);

  return (
    <aside className={`pricing-orange ${className}`}>
      <article
        className={`relative flex flex-col overflow-hidden rounded-md bg-white shadow-lg ${
          onClick && 'h-full'
        }`}
      >
        <span className="absolute h-3 w-full"></span>
        <header className="mt-3 px-6 pt-4 text-center">
          <div className="text-2xl font-bold capitalize">{t(currentPackage.name)}</div>
          <p>
            {currentPackage.use.quantity}&nbsp;
            {currentPackage.use.quantity > 1 ? (
              <span className="lowercase">{t('webshop_plural')}</span>
            ) : (
              <span className="lowercase">{t('webshop')}</span>
            )}
          </p>
        </header>
        <main className="px-6 py-4">
          <div className="grid grid-cols-[auto,1fr] grid-rows-2 gap-x-2">
            <span
              data-test-id="webshop-home-price"
              className="row-span-2 flex align-top text-5xl font-bold"
            >
              &euro;
              {/* TODO: fix this - Making it `!==` breaks prod/build? */}
              {/* eslint-disable-next-line eqeqeq  */}
              {prices != null ? (
                <>
                  {prices.get('webshop') ? prices.get('webshop')?.toFixed(2).split('.').at(0) : 0}
                  <span className="text-sm">
                    .{prices.get('webshop')?.toFixed(2).split('.').at(1) || null}
                  </span>
                </>
              ) : (
                <>
                  {price.toFixed(2).split('.').at(0)}
                  <span className="text-sm">.{price.toFixed(2).split('.').at(1) || null}</span>
                </>
              )}
            </span>
            <span className="">{t('per_year')}</span>
            <span className="">{t('excl_vat')}</span>
          </div>
          <hr className="my-4" />
          <div className="mb-2 flex items-center gap-x-1">
            <CheckMark className="check inline-block h-6 w-6 flex-shrink-0" />
            <p className="inline-block">
              {t('api_requests')}:&nbsp;
              <span className="font-bold">
                {currentPackage.apiRequests === 0 ? t('unlimited') : currentPackage.apiRequests}
              </span>
            </p>
          </div>
          <div className="mb-2 flex items-center gap-x-1">
            <CheckMark className="check inline-block h-6 w-6 flex-shrink-0" />
            <p className="inline-block">{t('integration_plugins')}</p>
          </div>
          <div className="mb-2 flex items-center gap-x-1">
            <CheckMark className="check inline-block h-6 w-6 flex-shrink-0" />
            <p className="inline-block">{t('two_month_trial')}</p>
          </div>
          <div className="mb-2 flex items-center gap-x-1">
            <CheckMark className="check inline-block h-6 w-6 flex-shrink-0" />
            <p className="inline-block">{t('monthly_updates')}</p>
          </div>
          {Object.entries(
            pricingData.packages.find((pricingPackage) => pricingPackage.name === 'webshop')
              .countries,
          ).map((pricingPackage, i: number) => {
            const country = pricingPackage.at(0);
            if (selectedCountries.has(country)) {
              return (
                <div key={i} className="mb-2 flex items-center gap-x-1">
                  <CheckMark className="check inline-block h-6 w-6 flex-shrink-0" />
                  <div className="inline-block">
                    <span className="capitalize">
                      {t('use')} {t(pricingPackage.at(0))}{' '}
                    </span>
                    {pricingPackage.at(1).features.map((feature: string, i: number) => {
                      const apiCall = feature === 'road distance' ? 'distance' : feature;
                      const country =
                        feature === 'reverse' ? 'global' : pricingPackage.at(0).toLowerCase();
                      const field = feature === 'suggest' ? '/postalCode' : '';

                      if (feature !== 'feedback' && feature !== 'autocomplete extra data') {
                        return (
                          <div key={feature} className="inline-block">
                            <Link
                              href={`/developer/${apiCall}/${country}${field}/parameters`}
                              className="inline-block lowercase text-blue underline"
                            >
                              {t(`demo:${feature}`)}
                            </Link>
                            {/* add comma to every element except last */}
                            {i !== pricingPackage.at(1).features.length - 2 ? ',' : null}
                            &nbsp;
                          </div>
                        );
                      }
                    })}
                    {t('features')}
                  </div>
                </div>
              );
            }
          })}
          {hideCountries ? (
            ''
          ) : (
            <div className="my-4 grid grid-cols-2 items-center gap-2 rounded md:gap-8 md:p-0">
              {Object.keys(pricingData.packages.at(0).countries).map((country) => {
                const name = country;
                return (
                  <Checkbox
                    key={name}
                    checked={selectedCountries ? selectedCountries.has(name) : false}
                    handleCheckboxChange={toggleCountry}
                    id={`${name}webshop`}
                    data-test-id={`${name}webshop`}
                    name={name}
                    isCountry
                  />
                );
              })}
            </div>
          )}
        </main>
        {hideButton ? (
          ''
        ) : (
          <div className="mt-auto px-6 py-4">
            <Link
              href={`${process.env.NEXT_PUBLIC_CMS_BASE_PATH}`}
              className={`btn block text-xl font-bold ${price === 0 ? 'disabled' : ''}`}
            >
              {currentPackage.trial ? t('free_trial') : t('buy_now')}
            </Link>
          </div>
        )}
        {!onClick ? null : (
          <div className="mt-auto px-6 py-4">
            <button className={`btn block ${price === 0 ? 'disabled' : ''}`} onClick={onClick}>
              {t('select')}
            </button>
          </div>
        )}
      </article>
    </aside>
  );
}
