import { type MouseEventHandler, type ReactElement } from 'react';
import Link from '@/modules/I18n/components/Link';
import { useTranslation } from 'next-i18next';
import type { Package } from '@/schemas/CMS/PaymentSSR';

export default function PricingPackage({
  pricingPackage,
  prices,
  selectedCountries,
  hideButton,
  onClick,
  className,
}: {
  pricingPackage: Package;
  prices: Map<string, number>;
  selectedCountries: Set<string>;
  hideButton?: boolean;
  onClick: MouseEventHandler<HTMLElement> | undefined;
  className?: string;
}) {
  const { t } = useTranslation(['pricing', 'demo']);

  const databaseDownloads: ReactElement[] = [];
  const apiCalls: ReactElement[] = [];

  // Go through all the selected countries
  selectedCountries.forEach((selectedCountry) => {
    // Find if the current package contains the selected country
    const countryObj = pricingPackage.countries[selectedCountry];

    if (countryObj) {
      // Get the databases for the selected country of the package
      countryObj.databases.forEach((db: string, i: number) => {
        const downLoadLink = (
          <>
            <div key={i} className="inline-block">
              {t('download_db_1')}{' '}
              <Link
                href={`/downloads/${selectedCountry.toLowerCase()}#${db.toLowerCase()}`}
                className="text-blue underline"
              >
                <span className="capitalize">{t(selectedCountry)}</span> {t(db.toLowerCase())}
              </Link>{' '}
              {t('download_db_2')}
            </div>
          </>
        );

        databaseDownloads.push(downLoadLink);
      });
    }
  });

  // Go through all the selected countries
  selectedCountries.forEach((selectedCountry) => {
    // Find if the current package contains the selected country
    const countryObj = pricingPackage.countries[selectedCountry];
    if (countryObj) {
      const apiCall = (
        <div key={selectedCountry} className="inline-block">
          <span className="capitalize">
            {t('use')} {t(selectedCountry)}{' '}
          </span>

          {countryObj.features.map((call: string) => {
            if (call === 'feedback' || call === 'autocomplete extra data') return null;

            const apiCall = call === 'road distance' ? 'distance' : call;
            const country = call === 'reverse' ? 'global' : selectedCountry.toLowerCase();
            const field = call === 'suggest' ? '/postalCode' : '';
            const isLastElement = call === countryObj.features.at(-1);

            return (
              <div key={call} className="inline-block">
                <Link
                  href={`/developer/${apiCall}/${country}${field}/parameters`}
                  className="inline-block lowercase text-blue underline"
                >
                  {t(`demo:${call}`)}
                </Link>
                {/* add comma to every element except last */}
                {!isLastElement ? ',' : null}
                &nbsp;
              </div>
            );
          })}

          {t('features')}
        </div>
      );

      apiCalls.push(apiCall);
    }
  });

  return (
    <article
      role="presentation"
      key={pricingPackage.name}
      onClick={onClick}
      className={`relative flex flex-col overflow-hidden rounded-md shadow-lg transition-all ${className}`}
    >
      <span className="absolute h-3 w-full"></span>
      <header className="mt-3 pt-4 text-center">
        <h3 className="text-2xl capitalize">{t(pricingPackage.name)}</h3>
        <p>
          {pricingPackage.use.quantity}&nbsp;
          {pricingPackage.use.quantity > 1 ? (
            <span className="lowercase">
              {t('website_plural')} {t('and')}/{t('or')} {t('app_plural')}
            </span>
          ) : (
            <span className="lowercase">
              {t('website')} {t('or')} {t('app')}
            </span>
          )}
        </p>
      </header>
      <main className="px-6 py-4">
        <div className="grid grid-cols-[auto,1fr] grid-rows-2 gap-x-2">
          <span
            data-test-id="package-price"
            className="row-span-2 flex align-top text-5xl font-bold"
          >
            &euro;
            {prices.get(pricingPackage.name)
              ? prices.get(pricingPackage.name)?.toFixed(2).split('.')[0]
              : 0}
            <span className="text-sm">
              .{prices.get(pricingPackage.name)?.toFixed(2).split('.')[1] || null}
            </span>
          </span>
          <span className="">{t('per_year')}</span>
          <span className="">{t('excl_vat')}</span>
        </div>
        <hr className="my-4" />
        <div className="mb-2 flex items-start gap-x-1">
          <svg
            className="check inline-block h-6 w-6 flex-shrink-0"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 13l4 4L19 7" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} />
          </svg>
          <div className="inline-block">
            {t('api_requests')}:
            <p className="font-bold">
              {pricingPackage.apiRequests === 0 ? t('unlimited') : pricingPackage.apiRequests}
            </p>{' '}
          </div>
        </div>

        {databaseDownloads.map((dbDL: ReactElement, i: number) => (
          <div key={dbDL.key || i} className="mb-2 flex items-start gap-x-1 last:mb-0">
            <svg
              className="check inline-block h-6 w-6 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13l4 4L19 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
              />
            </svg>
            {dbDL}
          </div>
        ))}

        {apiCalls.map((apiCall: ReactElement, i: number) => (
          <div key={apiCall.key || i} className="mb-2 flex items-start gap-x-1 last:mb-0">
            <svg
              className="check inline-block h-6 w-6 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 13l4 4L19 7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
              />
            </svg>
            {apiCall}
          </div>
        ))}
      </main>

      {!hideButton ? (
        <div className="mt-auto px-6 py-4">
          <Link
            href={`${process.env.NEXT_PUBLIC_CMS_BASE_PATH}`}
            className={`btn block cursor-pointer text-lg ${
              !prices.get(pricingPackage.name) ? 'disabled' : ''
            }`}
          >
            {t('buy_now')}
          </Link>
        </div>
      ) : null}

      {onClick ? (
        <div className="mt-auto px-6 py-4">
          <button
            className={`btn block cursor-pointer ${
              !prices.get(pricingPackage.name) ? 'disabled' : ''
            }`}
            onClick={onClick}
          >
            {t('select')}
          </button>
        </div>
      ) : null}
    </article>
  );
}
